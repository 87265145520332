import Model from '../model';

export default class OverlayModel extends Model {
  get isVisible() {
    const { isVisible } = this.value;
    return isVisible;
  }

  set isVisible(value) {
    this.value = { isVisible: value };
  }

  get isLoading() {
    const { isLoading } = this.value;
    return isLoading;
  }

  set isLoading(value) {
    this.value = { isLoading: value };
  }

  get fromName() {
    const { fromName } = this.value;
    return fromName;
  }

  set fromName(value) {
    this.value = { fromName: value };
  }

  get fromAvatar() {
    const { fromAvatar } = this.value;
    return fromAvatar;
  }

  set fromAvatar(value) {
    this.value = { fromAvatar: value };
  }

  get faceView() {
    const { faceView } = this.value;
    return faceView;
  }

  set faceView(value) {
    this.value = { faceView: value };
  }

  get placeholder() {
    const { placeholder } = this.value;
    return placeholder;
  }

  set placeholder(value) {
    this.value = { placeholder: value };
  }
}
