import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../../components/three-dot-loader';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 3000,
    color: '#fff',
  },
}));

export default function LoaderTemplate(props) {
  const { open, text, color = '#FFF' } = props;
  const material = useStyles();
  return (
    <Backdrop open={open} className={material.backdrop}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader style={{ marginBottom: '10px' }} color={color} />
        <Typography>
          <span style={{ color }}>{text}</span>
        </Typography>
      </div>
    </Backdrop>
  );
}
