import BaseFace from '@blockv/sdk/face/faces/BaseFace';
import BLOCKv from '../managers/blockv';

/** This face displays an image, and a video when activated. It also supports unlocking with a key if necessary. */
export default class VideoFace extends BaseFace {
  /** @override On load, refresh image */
  onLoad() {
    const { config = {} } = this.face.properties;
    const { viewMode = 'icon' } = this.vatomView;
    // Make sure required resources exist
    const image = this.vatom.properties.resources.find(
      (img) => img.name === (config.image || 'ActivatedImage')
    );
    const cover = this.vatom.properties.resources.find(
      (cv) => cv.name === (config.cover || 'CoverImage')
    );
    const video = this.vatom.properties.resources.find(
      (vid) => vid.name === (config.video || 'Video')
    );

    if (!image) return Promise.reject(new Error('ActivatedImage resource missing.'));
    if (!video) return Promise.reject(new Error('Video resource missing.'));

    // Create activated image
    this.activatedImage = document.createElement('div');
    this.activatedImage.style.cssText =
      'position: absolute; top:0; left:0; width: 100%; height: 100%; background-repeat: no-repeat; background-size: contain; background-position:center';

    this.element.appendChild(this.activatedImage);

    // Get resource
    let resource = viewMode === 'engaged' ? cover : null;

    if (!resource) resource = image;

    this.activatedImage.style.backgroundImage = `url(${BLOCKv.UserManager.encodeAssetProvider(
      resource.value.value
    )})`;

    // If activated, add click handler
    if (viewMode === 'engaged') {
      // Prepare the video player
      this.prepareVideo();

      // // Check if no key
      const unlockKey = config.unlock_key || this.vatom.private?.unlock_key;
      if (!unlockKey || unlockKey.length === 0) {
        // Attach click handler
        this.activatedImage.addEventListener('click', (e) => {
          e.preventDefault();

          // Activate the video
          this.activate();
        });
      }
    }

    // Return promise
    return VideoFace.waitForImage(BLOCKv.UserManager.encodeAssetProvider(resource.value.value));
  }

  /** This returns a promise which resolves when the specified image URL has been downloaded by the browser. */
  static waitForImage(url) {
    return new Promise((onSuccess, onFail) => {
      // Create new image tag to do the loading. Browsers cache requests together, so by
      // creating a new image tag and loading the same image in it, we can track the load
      // event of the background-image in the div above.
      const img = document.createElement('img');
      img.src = url;

      // Add event handlers
      img.onerror = onFail;
      img.onload = onSuccess;
    });
  }

  /** Check if we can combine with the specified vatom */
  canCombineWith(otherVatom) {
    const { config = {} } = this.face.properties;
    // Check for unlock key
    const unlockKey = config.unlock_key || this.vatom.private?.unlock_key;

    if (unlockKey)
      return otherVatom.properties.template_variation === unlockKey || otherVatom.id === unlockKey;

    // No key, do default behaviour
    return false;
  }

  /** Do the combine */
  combineWith(otherVatom) {
    if (this.canCombineWith(otherVatom)) {
      // Activate the video
      this.activate();
      return true;
    }
    return false;
  }

  /** Called when the face is loaded in engaged mode, but the user hasn't clicked it yet */
  prepareVideo() {
    // Stop if already prepared
    if (this.fullscreenContainer) return;

    // Create fullscreen container
    this.fullscreenContainer = document.createElement('div');
    this.fullscreenContainer.style.cssText =
      'position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: #FFF; z-index: 2000; opacity: 0; transition: opacity 0.3s; pointer-events: none;';
    document.body.appendChild(this.fullscreenContainer);

    // Create video element
    const videoResource = this.vatom.properties.resources.find((vid) => vid.name === 'Video');
    this.videoPlayer = document.createElement('video');
    this.videoPlayer.style.cssText = `z-index:1; position: absolute; top: 0px; left: 0px; width: 100%; background-color: rgba(0,0,0,0.9); height: 100%; object-fit:'cover';`;
    this.videoPlayer.poster = BLOCKv.UserManager.encodeAssetProvider(
      this.vatom.properties.resources.find((cv) => cv.name === 'CoverImage').value.value
    );
    this.videoPlayer.playsInline = true;
    this.videoPlayer.muted = true;
    this.videoPlayer.autoplay = false;
    this.videoPlayer.src = BLOCKv.UserManager.encodeAssetProvider(videoResource.value.value);

    this.fullscreenContainer.appendChild(this.videoPlayer);
    // Add play on tap handler
    this.videoPlayer.addEventListener('click', (e) => {
      this.videoPlayer.play();
      e.preventDefault();
    });
    this.videoPlayer.addEventListener('touchstart', (e) => {
      this.videoPlayer.play();
      e.preventDefault();
    });

    // Add close button
    const skipBtn = document.createElement('div');
    skipBtn.style.cssText =
      'position: absolute; top: 0px; right: 0px; padding: 20px; color: #08F; fontSize: 9px; fontFamily: Helvetica, Arial; cursor: pointer; textAlign: right; ';
    skipBtn.innerText = 'Skip';
    this.fullscreenContainer.appendChild(skipBtn);

    // Add skip button handler
    skipBtn.addEventListener('click', () => {
      // Remove overlay
      this.fullscreenContainer.style.opacity = '0';
      setTimeout(() => {
        this.fullscreenContainer.parentNode?.removeChild(this.fullscreenContainer);
      }, 500);
    });

    // Add handler when video is finished playing
    this.videoPlayer.addEventListener('ended', () => {
      // Remove element
      this.fullscreenContainer.style.opacity = '0';
      setTimeout(() => {
        this.fullscreenContainer.parentNode?.removeChild(this.fullscreenContainer);
      }, 500);
    });
  }

  /** Activate the video */
  activate() {
    // Prepare the video to be played. It may not have been prepared yet, if a key was dropped on it in Icon mode (ie in the inventory).
    this.prepareVideo();

    // Show it with animation
    this.fullscreenContainer.style.display = 'block';
    this.fullscreenContainer.style.pointerEvents = 'auto';
    if (this.videoPlayer.src) {
      this.fullscreenContainer.style.opacity = '1';
      this.videoPlayer.play();
    }
    // Do action
    const actionName =
      (this.face.config && this.face.config.activate_action) ||
      this.vatom.properties.activate_action ||
      'Activate';
    return BLOCKv.Vatoms.performAction(this.vatom, actionName, null).catch((err) => {
      // Immediately hide the video element
      this.fullscreenContainer.parentNode?.removeChild(this.fullscreenContainer);
      // Pass the error further down the promise chain
      throw err;
    });
  }

  static get url() {
    return 'native://video';
  }
}
