import React from 'react';

export default function VatomView(props) {
  const { faceView, style, className, onAttach, onDetach } = props;

  React.useEffect(() => {
    onAttach?.();
    return () => onDetach?.();
  });

  return (
    <div
      className={className}
      ref={(element) => {
        if (element) {
          if (faceView?.element?.parentNode !== element) {
            while (element.hasChildNodes()) {
              element.removeChild(element.lastChild);
            }
            if (faceView) {
              element.append(faceView.element);
            }
          }
        }
      }}
      style={{ width: '100%', height: '100%', ...style }}
    />
  );
}
