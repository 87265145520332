//
// Displays pixie dust animation during loading

import React from 'react';
import Particle from './particle';

export default class ConfettiLoader extends React.Component {
  constructor(props) {
    super(props);

    // Properties
    this.particleInterval = props.particleInterval || 5;
    this.particleSway = 50;
    this.particleLifetime = 10 * 1000;
    this.particleSpeed = 400;
    this.canvas = null;
    this.ctx = null;
    this.particles = [];
    this.lastParticleTime = 0;
    this.lastFrameTime = 0;
    this.state = {};

    // Pre-bind some functions
    this.renderCanvas = this.renderCanvas.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  /** On React component mount */
  componentDidMount() {
    // Add resize listener
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  /** On React component remove */
  componentWillUnmount() {
    // Clear canvas element
    this.canvas = null;
    this.ctx = null;

    // Remove listeners
    window.removeEventListener('resize', this.onResize);
  }

  /** Called when the view size changes */
  onResize() {
    // Stop if not loaded yet
    if (!this.canvas) {
      return;
    }

    // Set canvas size
    const scale = 1; // window.devicePixelRatio || 1
    const size = this.canvas.getBoundingClientRect();
    this.canvas.width = size.width * scale;
    this.canvas.height = size.height * scale;
  }

  renderCanvas() {
    // Stop if no canvas
    if (!this.canvas) {
      return;
    }

    // Do again on next render loop
    requestAnimationFrame(this.renderCanvas);

    // Calculate delta
    const delta = Math.min(0.5, (Date.now() - this.lastFrameTime) / 1000);
    this.lastFrameTime = Date.now();

    // Clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw each particle
    for (let i = 0; i < this.particles.length; i += 1) {
      // Draw particle
      const particle = this.particles[i];
      particle.draw(this.ctx, delta);

      // Remove dead particles
      if (particle.dead) {
        this.particles.splice(i, 1);
        i -= 1;
      }
    }

    // Check if it's time to create a new particle
    if (Date.now() - this.lastParticleTime > this.particleInterval) {
      // Create a particle, set isWhiteOnly to true
      const particle = new Particle(
        Math.random() * this.particleLifetime,
        Math.random() * this.canvas.width,
        -50,
        1, // window.devicePixelRatio || 1,
        Math.random() * this.particleSway - this.particleSway / 2,
        Math.random() * this.particleSpeed,
        true
      );

      // Add it
      this.particles.push(particle);
    }
  }

  /** Render the react component */
  render() {
    return (
      <canvas
        ref={(element) => {
          if (element) {
            // Fetch canvas element
            this.canvas = element;
            this.ctx = element.getContext('2d');
            // Start render loop
            this.renderCanvas();
          }
        }}
        style={{
          display: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    );
  }
}
