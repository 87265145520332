import ProgressImage from '@blockv/sdk/face/faces/ProgressImage';
import ImageFace from '@blockv/sdk/face/faces/ImageFace';
import ImagePolicy from '@blockv/sdk/face/faces/ImagePolicy';
import LayeredImage from '@blockv/sdk/face/faces/LayeredImage';
import BaseWebFace from '@blockv/sdk/face/faces/WebFace/BaseWebFace';
import ThreedFace from '../../faces/threed-face';
import VideoFace from '../../faces/video-face';
import ScannerFace from '../../faces/scanner-face';

class FaceRegistery {
  constructor() {
    this.faces = {
      'native://image': ImageFace,
      'native://progress-image-overlay': ProgressImage,
      'native://image-policy': ImagePolicy,
      'native://layered-image': LayeredImage,
      'https://*': BaseWebFace,
    };
    this.register(ThreedFace);
    this.register(VideoFace);
    this.register(ScannerFace);
  }

  register(faceView) {
    this.faces[faceView.url.toLowerCase()] = faceView;
  }

  getFaceView(url) {
    const displayUrl = url.toLowerCase();
    let FaceView = this.faces[displayUrl];
    if (FaceView === undefined && displayUrl.indexOf('http') !== -1) {
      FaceView = this.faces['https://*'];
    }
    return FaceView;
  }
}

export default new FaceRegistery();
