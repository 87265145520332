import { mergeMap } from 'rxjs/operators';
import { t } from 'i18next';
import Blockv from '../../managers/blockv';
import Alert from '../../util/alert';
import FaceManager from '../../managers/face-manager';
import FaceSelection from '../../managers/face-manager/face-selection';
import CompositeSubscription from '../../util/composite-subscription';
import FaceRegistery from '../../managers/face-manager/face-registery';

const allowedFaces = { ...FaceRegistery.faces };
delete allowedFaces['https://*'];
delete allowedFaces['native://generic-3d'];
delete allowedFaces['native://generic-3d-folder'];

export default class Presenter {
  constructor(model, vatom, onCloseListener) {
    this.model = model;
    this.vatom = vatom;
    this.onCloseListener = onCloseListener;
    this.isClosed = false;
    this.subscriptions = new CompositeSubscription();
  }

  onAttach() {
    this.attached = true;
    // Log analytics
    Analytics.screen('IncomingOverlay');

    // Load previous user's info
    const fromUser = this.vatom.properties.author;
    if (!fromUser) {
      this.model.fromName = 'User';
      this.model.fromAvatar = null;
      return;
    }
    // Fetch user info
    Blockv.UserManager.getPublicUserProfile(fromUser)
      .then((user) => {
        const avatar = Blockv.UserManager.encodeAssetProvider(user.properties.avatar_uri);
        // Update UI
        if (this.attached) {
          this.model.fromName = user.properties.first_name;
          this.model.fromAvatar = avatar;
        }
      })
      .catch((err) => {
        // Unable to get user details
        console.warn('Unable to fetch user info', err);
        if (this.attached) {
          this.model.fromName = 'User';
          this.model.fromAvatar = null;
        }
      });

    this.model.isVisible = true;
    this.model.isLoading = true;

    const fsp = FaceSelection.DefaultRoutine;
    const face =
      fsp(this.vatom.faces, allowedFaces, {
        view_mode: 'icon',
        platform: 'web',
      }) ||
      fsp(this.vatom.faces, allowedFaces, {
        view_mode: 'icon',
        platform: 'generic',
      });

    this.subscriptions.clear();
    this.model.isLoading = true;
    this.subscriptions.add(
      FaceManager.getFaceView(this.vatom, face)
        .pipe(
          mergeMap((faceView) => {
            this.model.faceView = faceView;
            return FaceManager.loadFaceView(faceView);
          })
        )
        .subscribe(
          () => {
            this.onReadyToDisplay();
          },
          async (error) => {
            console.log(error);
            const resource = this.vatom.properties?.resources?.find(
              (res) => res.name === 'ActivatedImage'
            );
            if (resource) {
              this.model.placeholder = Blockv.UserManager.encodeAssetProvider(resource.value.value);
              this.onReadyToDisplay();
            } else await this.onLoadFailed();
          }
        )
    );
  }

  onDetach() {
    this.attached = false;
    this.subscriptions.clear();
  }

  /** Called when the user clicks on the vAtom */
  onClicked() {
    // Close us
    this.onClose();
    // Go to the vAtom's activated view
    window.location.hash = `/vatom/${this.vatom.id}`;
  }

  /** Called when the user closes us */
  onClose() {
    if (this.isClosed) return;
    this.isClosed = true;
    // Hide view
    this.model.isVisible = false;

    // Notify listener
    setTimeout(() => {
      this.onCloseListener?.();
    }, 1000 * 1);

    // Stop auto hide timer
    if (this.autoHideTimer) {
      clearTimeout(this.autoHideTimer);
    }
  }

  onReadyToDisplay() {
    if (!this.attached) return;
    // Show
    this.model.isLoading = false;

    // Auto hide soon
    this.autoHideTimer = setTimeout(this.onClose.bind(this), 3000);
  }

  async onLoadFailed() {
    if (!this.attached) return;

    this.onClose?.();
    // Show error
    await Alert.notify(
      t('incoming-overlay.error_title'),
      t('incoming-overlay.error_unable_to_fetch_vatom'),
      t('incoming-overlay.error_confirm')
    );
  }
}
