/* eslint-disable no-param-reassign */
import { Observable } from 'rxjs';
import Blockv from '../blockv';
import FaceRegistyery from './face-registery';

class FaceManager {
  getFaceView(
    vatom,
    face,
    onMessageHandler = (name) => {
      console.warn(`No message handler ${name}`);
    },
    customData
  ) {
    return new Observable((subscriber) => {
      try {
        if (face === undefined) {
          throw new Error('No face found for this view mode.');
        }
        const FaceView = FaceRegistyery.getFaceView(face.properties.display_url);
        if (FaceView === undefined) {
          throw new Error('No Face Registered');
        }

        const faceView = new FaceView(
          {
            vatom,
            blockv: Blockv,
            onMessage: onMessageHandler,
            ...customData,
          },
          vatom,
          face
        );
        subscriber.next(faceView);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
      }
    });
  }

  unloadFaceView(faceView) {
    faceView.onUnload?.();
    faceView.vatomView = null;
    faceView.face = null;
    faceView.element = null;
  }

  loadFaceView(faceView) {
    return new Observable((subscriber) => {
      let canceled = false;
      faceView
        .onLoad()
        .then(() => {
          if (!canceled) {
            subscriber.next(faceView);
          } else {
            this.unloadFaceView(faceView);
          }
        })
        .catch((err) => {
          this.unloadFaceView(faceView);
          subscriber.error(err);
        });
      return () => {
        this.unloadFaceView(faceView);
        canceled = true;
      };
    });
  }
}

export default new FaceManager();
