import EventEmitter from '../../util/event-emitter';

/**
 *  The NetworkManager singleton manages control over the device's network, including registering a Service Worker
 *  to manage caching of requests.
 */
export default new (class NetworkManager extends EventEmitter {
  /** Called the first time this singleton is accessed. */
  constructor() {
    super();

    /** Possible states for the app cache */
    this.States = {
      /** Service worker is not installed */
      Unavailable: 'unavailable',

      /** Checking for update */
      Checking: 'checking',

      /** Downloading app updates */
      Downloading: 'downloading',

      /** Update downloaded, will be used on next launch */
      UpdatesPending: 'updates-pending',

      /** App is up to date */
      UpToDate: 'up-to-date',

      /** App download failed */
      Failed: 'failed',
    };

    /** If the state is Downloading, this contains the download progress, in number of files */
    this.downloadCurrent = 0;
    this.downloadTotal = 0;

    /** The current state of the cache */
    this.appCacheState = this.States.Unavailable;

    /** If true, the current version of the running app came from the cache */
    this.servedFromCache = false;
  }

  /**
   *  Called to update the state of the network manager, and notify listeners
   *
   *  @private
   */
  setState(s) {
    this.appCacheState = s;
    this.emit('statechange');
  }

  /**
   *  Called on app startup to register components
   */
  async register() {
    // Catch errors
    try {
      // Check if Service Workers are supported
      if (!navigator.serviceWorker) {
        return console.warn('[NetworkManager] Service workers are not supported in this browser.');
      }

      // Add listeners
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        this.onControllerChange.bind(this)
      );
      navigator.serviceWorker.addEventListener('message', this.onMessage.bind(this));

      // Get URL to the service worker code
      const path = require('file-loader?name=service-worker.js!./ServiceWorker.nobuild.js');

      // Register service worker
      const registration = await navigator.serviceWorker.register(path);

      // Notify changed
      this.onControllerChange();
    } catch (err) {
      // Failed!
      console.warn('[NetworkManager] Unable to register. ', err);
    }
  }

  /**
   *  Called by the browser when the active service worker changes
   *
   *  @private
   */
  onControllerChange() {
    // Check if no service worker
    if (!navigator.serviceWorker || !navigator.serviceWorker.controller) {
      return this.setState(this.States.Unavailable);
    }

    // Ask service worker to check for updates again
    this.checkForUpdates();
  }

  /**
   *  Called by the browser when our service worker has a message for us
   *
   *  @private
   */
  onMessage(e) {
    // Make sure it's a state update message
    if (e.data.action != 'state-update') {
      return;
    }

    // Update state
    const previousState = this.appCacheState;
    this.appCacheState = e.data.state.state || this.States.Unavailable;
    this.downloadTotal = e.data.state.downloadTotal || 0;
    this.downloadCurrent = e.data.state.downloadCurrent || 0;
    this.error = e.data.state.error;

    if (typeof e.data.state.servedFromCache !== 'undefined') {
      this.servedFromCache = e.data.state.servedFromCache;
    }

    // For the special case, if state was Downloading and is now UpToDate, we should move this particular instance
    // to PendingUpdates
    if (
      this.servedFromCache &&
      previousState == this.States.Downloading &&
      this.appCacheState == this.States.UpToDate
    ) {
      this.appCacheState = this.States.UpdatesPending;
    }

    // Log our new state
    if (this.appCacheState == this.States.Downloading) {
      console.log(
        `[NetworkManager] State is now downloading: ${this.downloadCurrent} of ${this.downloadTotal} files`
      );
    } else if (this.appCacheState == this.States.Failed) {
      console.log('[NetworkManager] Failed to update: ', this.error);
    } else {
      console.log(`[NetworkManager] State is now ${this.appCacheState}`);
    }

    // Notify listeners
    this.emit('statechange');

    // While downloading updates, keep the service worker active by sending messages to it
    if (this.appCacheState == this.States.Downloading && !this.wakeTimer) {
      this.wakeTimer = setTimeout((e) => {
        // Send wake message
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ action: 'wake' });
        }

        // Clear timer
        this.wakeTimer = null;
      }, 1000);
    }
  }

  /**
   *  Asks the Service Worker to check for updates again.
   */
  checkForUpdates() {
    // Send request
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ action: 'check-updates' });
    }
  }

  /**
   *  The app can call this when the state is UpdatesPending, to refresh the app and install the updates.
   */
  installUpdates() {
    window.location.reload();
  }
})();
