import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function InformTemplate(props) {
  const { open, message, onClose } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={() => onClose?.()}
      message={message}
    />
  );
}
