export default class CompositeSubscription {
  constructor() {
    this.subscriptions = new Set();
  }

  clear() {
    const { subscriptions } = this;
    this.subscriptions = new Set();
    subscriptions.forEach((subscription) => {
      try {
        subscription?.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    });
    subscriptions.clear();
  }

  add(subscription) {
    this.subscriptions.add(subscription);
  }

  remove(subscription) {
    this.subscriptions.remove(subscription);
  }
}
