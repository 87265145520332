import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function AlertTemplate(props) {
  const {
    open,
    title,
    content,
    onClose,
    acceptText,
    onAccept,
    rejectText,
    onReject,
    htmlContentText,
  } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => onClose?.()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {htmlContentText ? (
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ) : (
          <DialogContentText>{content}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {rejectText && (
          <Button onClick={() => onReject?.()} color="primary">
            {rejectText}
          </Button>
        )}
        {acceptText && (
          <Button onClick={() => onAccept?.()} color="primary" autoFocus>
            {acceptText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
