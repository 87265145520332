import FaceRegistery from './face-registery';

class FaceSelectionProcedure {
  constructor() {
    this.defaultRoutine = (faces = [], faceRegistry = {}, constraints = {}) => {
      let selectedFace = null;
      const constraintKeys = Object.keys(constraints);
      for (let i = 0; i < faces.length; i += 1) {
        const face = faces[i];
        let constraintMatch = true;
        for (let o = 0; o < constraintKeys.length; o += 1) {
          const constraint = constraintKeys[o];
          if (face.properties.constraints[constraint] !== constraints[constraint]) {
            constraintMatch = false;
            break;
          }
        }
        if (constraintMatch) {
          const { display_url: displayUrl } = face.properties;
          if (displayUrl.startsWith('native://')) {
            if (faceRegistry[displayUrl]) {
              selectedFace = face;
            }
          } else if (faceRegistry['https://*'] && !selectedFace) {
            selectedFace = face;
          }
        }
      }
      return selectedFace;
    };
  }

  get DefaultRoutine() {
    return this.defaultRoutine;
  }

  get Icon() {
    return (vatom) =>
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'icon',
        platform: 'web',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'icon',
        platform: 'generic',
      });
  }

  get Engaged() {
    return (vatom) =>
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'engaged',
        platform: 'web',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'engaged',
        platform: 'generic',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'icon',
        platform: 'web',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'icon',
        platform: 'generic',
      });
  }

  get Fullscreen() {
    return (vatom) =>
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'fullscreen',
        platform: 'web',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'fullscreen',
        platform: 'generic',
      });
  }

  get Card() {
    return (vatom) =>
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'card',
        platform: 'web',
      }) ||
      this.defaultRoutine(vatom.faces, FaceRegistery.faces, {
        view_mode: 'card',
        platform: 'generic',
      });
  }
}
export default new FaceSelectionProcedure();
