//
// This class displays an overlay over the app to show a new vatom.
import React, { Component } from 'react';
import View from './view';
import Model from './model';
import Presenter from './presenter';

let provider = null;
let currentOverlay = null;
let paused = false;
let pendingOverlayVatom = null;

export default class IncomingOverlay extends Component {
  static setProvider(value) {
    provider = value;
  }

  /** If true, will prevent the incoming overlay from being displayed. */
  static get isPaused() {
    return paused;
  }

  static set isPaused(value) {
    // Set it
    paused = value;
    // Show pending overlay vatom if any
    if (!value && pendingOverlayVatom) {
      this.show(pendingOverlayVatom);
      pendingOverlayVatom = null;
    }
  }

  /**
   *  Shows an overlay for the specified vatom.
   *
   *  @param {Vatom} vatom The vatom to display.
   */
  static show(vatom) {
    // Stop if incoming overlay is hidden
    if (IncomingOverlay.isPaused) {
      pendingOverlayVatom = vatom;
      console.warn('IncomingOverlay skipped since paused is set');
      return;
    }

    // Check if already displaying vatom
    if (currentOverlay) {
      console.warn('IncomingOverlay already displaying a vatom, show() ignored.');
      return;
    }

    // Create and show
    provider(<IncomingOverlay vatom={vatom} />);
  }

  constructor(props) {
    super(props);
    const { vatom } = props;
    this.state = {
      isVisible: false,
      isLoading: false,
      fromName: null,
      fromAvatar: null,
      faceView: null,
      placeholder: null,
    };
    this.presenter = new Presenter(new Model(this), vatom, () => provider(undefined));
  }

  componentDidMount() {
    this.presenter.onAttach();
    currentOverlay = this.vatom;
  }

  componentWillUnmount() {
    this.presenter.onDetach();
    currentOverlay = null;
  }

  render() {
    return <View {...this.state} presenter={this.presenter} />;
  }
}
