import { JSFile } from 'js-file-manager';
import Credentials from '../../credentials';

/** Parses urls from image recognition */
export default class CraftARProcessor {
  /** Constructor */
  constructor(callback) {
    // The callback
    this.callback = callback;

    // True if still busy processing the last provided frame
    this.busy = false;

    // The last scanned result
    this.lastScan = null;

    // Date of the last image scan
    this.lastImageRecognitionTime = 0;

    // Time between scans in milliseconds
    this.timeBetweenScans = 1000;
  }

  /** Parse data from the canvas */
  process(canvas, ctx) {
    // Stop if not enough time has passed since our last image recognition attempt
    if (this.lastImageRecognitionTime + this.timeBetweenScans > Date.now()) return;
    this.lastImageRecognitionTime = Date.now();

    // Skip if busy
    if (this.busy) return;
    this.busy = true;

    // Convert canvas to a jpg blob
    const dataURI = canvas.toDataURL('image/jpeg', 0.5);
    console.log(
      `[Scanner > CraftARProcessor] Uploading image (${Math.round(dataURI.length / 1000)} KB)`
    );
    JSFile.fromURL(dataURI)
      .getBlob()
      .then((file) => {
        // Send file to Catchoom
        const form = new FormData();
        form.set('token', Credentials.catchoomToken);
        form.set('image', file, 'query.jpg');
        return fetch('https://search.craftar.net/v1/search', {
          method: 'POST',
          body: form,
        }).then((r) => r.json());
      })
      .then((response) => {
        // Throw errors
        if (response.error) {
          const err = new Error(response.error.message);
          err.code = response.error.code;
          throw err;
        }

        // Stop if no results
        if (!response.results || response.results.length == 0) {
          return;
        }

        // Use content of first result, if any
        const { url } = response.results[0].item;
        if (!url) {
          return;
        }

        // Process link
        console.log(`[Scanner > CraftARProcessor] Returned: ${url}`);
        this.callback(url);
      })
      .catch((err) => {
        // Image recognition error
        console.warn('[Scanner > CraftARProcessor] Image recognition error', err);
      })
      .then((e) => {
        // Ready to try again
        this.busy = false;
      });
  }
}
