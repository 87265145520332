//
// Draws a particle on a canvas
export default class Particle {
  constructor(lifetime, x, y, scale, velocityX, velocityY, isWhiteOnly = false) {
    // Properties
    this.lifeStart = Date.now();
    this.lifeDuration = lifetime;
    this.lifeEnd = this.lifeStart + this.lifeDuration;
    this.x = x;
    this.y = y;
    this.scale = scale;
    this.velocityX = velocityX;
    this.velocityY = velocityY;
    this.dead = false;
    const randomShade = Math.random() + 0.4;
    this.color = isWhiteOnly
      ? {
          red: randomShade,
          green: randomShade,
          blue: randomShade,
        }
      : {
          red: Math.random(),
          green: Math.random(),
          blue: Math.random(),
        };
  }

  draw(ctx, delta) {
    // Get opacity
    const opacity = Math.max(0, (Date.now() - this.lifeStart) / this.lifeDuration);

    // Get lifetime progress (0.0 - 1.0)
    const age = Math.min(1, (Date.now() - this.lifeStart) / this.lifeDuration);

    // Draw confetti
    ctx.fillStyle = `rgba(${Math.floor(this.color.red * 255)}, ${Math.floor(
      this.color.green * 255
    )}, ${Math.floor(this.color.blue * 255)}, ${1 - age})`;
    ctx.fillRect(this.x, this.y, 5 * this.scale, 5 * this.scale);

    // Move position
    this.x += this.velocityX * delta;
    this.y += this.velocityY * delta;

    // Check if dead
    if (age >= 1) {
      this.dead = true;
    }
  }
}
