import React from 'react';
import Overlay from './index';

export default function AlertProvider(props) {
  const [overlay, setOverlay] = React.useState(undefined);
  Overlay.setProvider(setOverlay);
  const { children } = props;
  return (
    <>
      {overlay}
      {children}
    </>
  );
}
