export default class Model {
  constructor(component) {
    this.component = component;
  }

  get value() {
    return this.component.state;
  }

  set value(state) {
    this.component.setState(state);
  }
}
