import BaseWebFace from '@blockv/sdk/face/faces/WebFace/BaseWebFace';
import React from 'react';
import ReactDOM from 'react-dom';
import ScannerView from '../components/scanner-view';

export default class ScannerWebFace extends BaseWebFace {
  /** Native face URL */
  static url = 'native://scanner-web';

  /** Load promise. Will be non-null if the camera is active. */
  loadPromise = null;

  /** Callbacks for the load promise. Will be non-null only during loading. */
  loadPromiseCallbacks = null;

  /** Called on load */
  async onLoad() {
    await super.onLoad();

    // Get web face url
    const config = this.face.properties.config || {};
    if (!config.url) throw new Error('No "url" field specified in the face config.');

    // Load it
    this.iframe.style.cssText += 'z-index: 1; ';
    this.iframe.setAttribute('src', config.url);
  }

  /** Called on unload */
  onUnload() {
    super.onUnload();
    this.disableCamera();
  }

  /** Called when a bridge message comes in */
  processIncomingBridgeMessage(name, payload) {
    // Check if we know what to do with it
    if (name === 'viewer.webscanner.enable') {
      // Enable
      return this.enableCamera();
    }
    if (name === 'viewer.webscanner.disable') {
      // Disable
      this.disableCamera();
    } else {
      // Pass on
      return super.processIncomingBridgeMessage(name, payload);
    }
  }

  /** Create camera view. @returns Promise */
  enableCamera() {
    // Stop if loaded already
    if (this.loadPromise) return this.loadPromise;

    // Create pending promise
    this.loadPromise = new Promise((resolve, reject) => {
      this.loadPromiseCallbacks = { resolve, reject };
    });

    // Create camera div
    this.cameraDiv = document.createElement('div');
    this.cameraDiv.style.cssText =
      'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 0; ';
    this.element.appendChild(this.cameraDiv);

    // Render loader
    ReactDOM.render(
      <ScannerView
        noImageRecognition
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        onContent={this.onScanned.bind(this)}
        onError={this.onCameraError.bind(this)}
        onLoad={this.onCameraLoad.bind(this)}
      />,
      this.cameraDiv
    );

    // Wait for load
    return this.loadPromise;
  }

  /** Remove camera view */
  disableCamera() {
    // Stop if removed already
    if (!this.loadPromise) return;

    // Unrender
    ReactDOM.unmountComponentAtNode(this.cameraDiv);
    this.element.removeChild(this.cameraDiv);
    this.cameraDiv = null;

    // Remove load promise
    this.loadPromise = null;
    this.loadPromiseCallbacks = null;
  }

  /** Called when the scanner component has loaded successfully */
  onCameraLoad() {
    // Resolve promise
    if (this.loadPromiseCallbacks) this.loadPromiseCallbacks.resolve(true);
    this.loadPromiseCallbacks = null;
  }

  /** Called when the scanner component fails to load */
  onCameraError(err) {
    // Reject promise
    if (this.loadPromiseCallbacks) this.loadPromiseCallbacks.reject(err);
    this.loadPromiseCallbacks = null;

    // Remove camera
    this.disableCamera();
  }

  /** Called when QR code is scanned */
  onScanned(text) {
    // Send to web face
    const id = Math.random().toString(36).substr(2);
    this.sendV2Message(id, 'viewer.webscanner.found', { text }, true);
  }
}
