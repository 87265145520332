import Worker from './QRProcessor.worker.js';
/** Parses QR codes from an image */
export default class QRProcessor {
  /** Constructor */
  constructor(callback) {
    // The callback
    this.callback = callback;

    // True if still busy processing the last provided frame
    this.busy = false;

    // The last scanned result
    this.lastScan = null;

    // Create web worker
    this.thread = new Worker();
    this.thread.addEventListener('message', this.onMessageFromWorker.bind(this));
  }

  /** Terminate worker */
  terminate() {
    this.thread.terminate();
    this.thread = null;
  }

  /** Parse data from the canvas */
  process(canvas, ctx) {
    // Skip if busy
    if (this.busy) return;
    this.busy = true;

    // Extract image pixel data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // Send image data to the web worker
    this.thread.postMessage(
      {
        action: 'detect',
        width: imageData.width,
        height: imageData.height,
        data: imageData.data.buffer,
      },
      [imageData.data.buffer]
    );
  }

  /** @private Called when a message is received from the worker */
  onMessageFromWorker(e) {
    // Got response from image processor
    if (e.data.action == 'complete') {
      // Done
      this.busy = false;
    } else if (e.data.action == 'result') {
      // Ignore if no result
      if (!e.data.value) {
        return;
      }

      // Ignore if this is the same as the previous scanned result
      if (e.data.value == this.lastScan) return;
      this.lastScan = e.data.value;

      // Found a value! Process it
      console.log(`[Scanner > QRProcessor] Worker returned result: ${e.data.value}`);
      this.callback(e.data.value);
    }
  }
}
