import { t } from 'i18next';
/** `Notifications` manages the permission and displaying of user notifications. */
export default new (class Notifications {
  /** Get if the user has granted permissions */
  get supported() {
    return typeof Notification !== 'undefined' && Notification.permission == 'granted';
  }

  /** Get enabled status */
  get enabled() {
    // Check if notifications are supported
    if (typeof Notification === 'undefined') {
      return false;
    }

    // Check if permission granted
    if (Notification.permission !== 'granted') {
      return false;
    }

    // Check if disabled in the app
    if (!localStorage.getItem('com.blockv:ui.notifications.enabled')) {
      return false;
    }

    // All checks passed, it is enabled
    return true;
  }

  /** Returns a status string, such as "No Support", "No Permission", "Disabled", "Enabled", etc */
  get status() {
    // Check if notifications are supported
    if (typeof Notification === 'undefined') {
      return t('notification.no_support');
    }

    // Check if permission granted
    if (Notification.permission === 'denied') {
      return t('notification.no_permission');
    }

    // Check if permission granted
    if (Notification.permission === 'default') {
      return t('notification.disabled');
    }

    // Check if disabled in the app
    if (!localStorage.getItem('com.blockv:ui.notifications.enabled')) {
      return t('notification.disabled');
    }

    // All checks passed, it is enabled
    return t('notification.enabled');
  }

  /** Enable push notifications. This displays a permission request if needed.
   *  @returns {Promise<bool>} true if successful, false if cancelled by the user. */
  async enable() {
    // Check current state of notifications
    if (typeof Notification === 'undefined') {
      // This browser doesn't support notifications.
      throw new Error(t('notification.error_no_browaser_support'));
    }

    // Check if the user has already disabled permissions
    if (Notification.permission === 'denied') {
      throw new Error(t('notification.error_permission_denied'));
    }

    // Check if notification permission has not been granted
    if (Notification.permission === 'default') {
      // Ask user to grant it
      const result = await Notification.requestPermission();
      if (result != 'granted') {
        return false;
      }

      // Enable notifications
      localStorage.setItem('com.blockv:ui.notifications.enabled', 'true');
    } else {
      // User already granted permission, just toggle the value
      const current = localStorage.getItem('com.blockv:ui.notifications.enabled');
      localStorage.setItem('com.blockv:ui.notifications.enabled', current ? '' : 'true');
    }

    // Success
    return true;
  }

  /** Disables notifications */
  disable() {
    // Disable notifications
    localStorage.setItem('com.blockv:ui.notifications.enabled', '');
  }

  /** Display a notification. @returns {Notification} The notification, or null if not enabled. */
  display(title, opts) {
    // Check if enabled
    if (!this.enabled) {
      return null;
    }

    // Create and return notification (notifications are displayed
    // immediately when they're constructed)
    return new Notification(title, opts);
  }
})();
