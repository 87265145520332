class Storage {
  set(key, value, mode = Storage.Mode.Persistent) {
    if (typeof localStorage !== 'undefined') {
      try {
        if (mode === Storage.Mode.Persistent) {
          localStorage[key] = value;
          return true;
        }
      } catch (error) {
        console.warn(error);
      }
    }
    return false;
  }

  get(key, mode = Storage.Mode.Persistent) {
    try {
      if (typeof localStorage !== 'undefined') {
        if (mode === Storage.Mode.Persistent) {
          return localStorage[key];
        }
      }
    } catch (error) {
      console.warn(error);
    }
    return undefined;
  }

  getInt(key, mode = Storage.Mode.Persistent) {
    const value = this.get(key, mode);
    if (value) {
      return parseInt(value, 10);
    }
    return value;
  }
}

Storage.Mode = {
  Persistent: 0,
};
export default new Storage();
