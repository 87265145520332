//
// Fetch country list

/** Get countries, and return a promise. */
export default class Countries {
  static get() {
    return import('countries-list').then((data) => {
      const { countries } = data;
      const items = [];
      Object.keys(countries).forEach((code) => {
        // Create country object
        const info = {
          code: code.toLowerCase(),
          name: countries[code].name,
          label: code,
          phonePrefix: countries[code].phone ? `+${countries[code].phone}` : '',
        };

        // Add it to array
        items.push(info);
      });
      // Sort array
      items.sort((a, b) => a.name.localeCompare(b.name));

      // Update data
      return items;
    });
  }

  // Detect the current user's country info
  static async detect() {
    // Return if already detected
    if (Countries.detected) {
      return Countries.detected;
    }

    // Load items
    const countries = await Countries.get();

    // Fetch our country code
    const response = await fetch('https://geoip.blockv.io/json/');
    const data = await response.json();

    // Find matching item
    const country = countries.find((c) => c.code.toLowerCase() == data.country_code.toLowerCase());

    // Set it as our detected country
    if (country) {
      Countries.detected = country;
      console.log('Detected country: ', country);
    }

    // Done
    return country;
  }
}
