import React from 'react';
import i18n, { t } from 'i18next';
import ErrorList from './errors.json';
import Alert from '../../util/alert';

class Errors {
  static async show(error, title) {
    // Log to the console
    console.warn(error);

    const detail = ErrorList.find((e) => e.codes.includes(error.code)) || {
      is_visible: true,
      message: {
        title: '',
        body: '',
      },
    };

    const titleText =
      (i18n.exists(`errors.messages.${detail.handle}.title`) &&
        t(`errors.messages.${detail.handle}.title`)) ||
      title ||
      t('errors.title_default');

    const detailText =
      (i18n.exists(`errors.messages.${detail.handle}.body`) &&
        t(`errors.messages.${detail.handle}.body`)) ||
      (error.code ? t('errors.message_default') : error.message);

    const moreInfo = (
      <table style={{ border: 'none', fontSize: '11px', userSelect: 'text', textAlign: 'left' }}>
        <tr>
          <td style={{ width: '100px', verticalAlign: 'top' }}>
            <b>{t('errors.more_info.http_code')}</b>
          </td>
          <td style={{ verticalAlign: 'top' }}>{error.httpStatus || '(none)'}</td>
        </tr>
        <tr>
          <td style={{ width: '100px', verticalAlign: 'top' }}>
            <b>{t('errors.more_info.error_code')}</b>
          </td>
          <td style={{ verticalAlign: 'top' }}>{error.code || '(none)'}</td>
        </tr>
        <tr>
          <td style={{ width: '100px', verticalAlign: 'top' }}>
            <b>{t('errors.more_info.server_message')}</b>
          </td>
          <td style={{ verticalAlign: 'top' }}>
            {error.serverMessage || error.message || '(none)'}
          </td>
        </tr>
        <tr>
          <td style={{ width: '100px', verticalAlign: 'top' }}>
            <b>{t('errors.more_info.request_id')}</b>
          </td>
          <td style={{ verticalAlign: 'top' }}>{error.requestID || '(none)'}</td>
        </tr>
        <tr>
          <td style={{ width: '100px', verticalAlign: 'top' }}>
            <b>{t('errors.more_info.error_message')}</b>
          </td>
          <td style={{ verticalAlign: 'top' }}>{error.message || '(none)'}</td>
        </tr>
      </table>
    );
    // Show error
    await Alert.notifyDetail(
      titleText,
      detailText,
      error.code && moreInfo,
      t('errors.action_accept'),
      t('errors.action_more_info')
    );
  }
}

export default Errors;
